import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';

const InfoPopper = ({ title }) => {
    return (
        <Box sx={{
            position: 'absolute',
            top: '0',
            right: '0'
        }}>
            <Tooltip 
                placement='top'
                title={title}>
                <IconButton>
                    <InfoIcon />
                </IconButton>
            </Tooltip>
        </Box>
    );
}

export default InfoPopper;