import Typography from '@mui/material/Typography';
import BoldText from './boldText';
import TextField from '@mui/material/TextField';
import ActionButton from './actionButton';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SendIcon from '@mui/icons-material/Send';
import '../styles/passwordForm.css';
import { forwardRef, useRef } from 'react';
import Paper from '@mui/material/Paper';
import InfoPopper from './infoPopper';
import Box from '@mui/material/Box';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';


const PasswordForm = forwardRef(({ 
                        password, 
                        passwordInput, 
                        setPasswordInput, 
                        passwordError, 
                        setPasswordError,
                        passwordSuccess,
                        setPasswordSuccess,
                        trackedData, 
                        setTrackedData,
                        submitData,
                        loading,
                        setLoading,
                        capsLock
                     }, ref) => {

    const inputRef = useRef(false);
    const keyDownRef = useRef(false);
    const composedRef = useRef(false);

    const fillMissingData = (endTime) => {
        // copy
        let inputData = JSON.parse(JSON.stringify(trackedData.inputs));
        inputData.forEach((val, i) => {
            if(val.flightTime === null) {
                val.flightTime = val.timeDown - inputData[i - 1].timeUp;
            }

            if(val.interKeyTime === null) {
                val.interKeyTime = val.timeDown - inputData[i - 1].timeDown;
            }

            if(val.timeUp === null) {
                val.timeUp = endTime;
            }

            if(val.timePressed === null) {
                val.timePressed = val.timeUp - val.timeDown;
            }

            if(val.timeDownFromStart === null) {
                val.timeDownFromStart = val.timeDown - trackedData.startTime;
            }

            if(val.timeUpFromStart === null) {
                val.timeUpFromStart = val.timeUp - trackedData.startTime;
            }
        });

        const filledData = {
            startTime: trackedData.startTime,
            focusTime: trackedData.focusTime,
            inputs: inputData,
            endTime: endTime
        };
        return filledData;
    };

    const submit = (e) => {
        e.preventDefault();
        ref.current?.blur();
        setLoading(true);
        const endTime = Date.now();
        const data = fillMissingData(endTime);
        submitData(data);
    };

    const stopAction = (msg) => {
        setPasswordInput('');
        setTrackedData({ startTime: null, focusTime: null, inputs: [] });
        setPasswordSuccess(false);
        setPasswordError(msg);
        ref.current?.blur();
    };

    const passwordChange = (e) => {
        if(!keyDownRef.current && !inputRef.current) {
            keyDownRef.current = false;
            inputRef.current = false;
            stopAction('Action forbidden');
            return
        }
        const val = e.target.value;
        if(val.length < passwordInput.length) {
            stopAction('Deletion forbidden');
        } else {
            setPasswordSuccess(false);
            setPasswordError('');
            setPasswordInput(val);
        }
        
        
        keyDownRef.current = false;
        inputRef.current = false;
    };

    const keyDown = (e) => {
        const key = e.key;
        
        // with safari keydown is fired after change when composed key
        if(!composedRef.current) {
            keyDownRef.current = true;
        }

        if(key !== 'Enter' && key !== 'Tab') {
            const cTime = Date.now();
            const valueBefore = e.target.value;
            // copy
            let inputData = JSON.parse(JSON.stringify(trackedData.inputs));
            const inputsLength = inputData.length;
            const index = inputsLength - 1;
            
            //first key
            if(inputsLength === 0) {
                setTrackedData({
                    startTime: cTime,
                    focusTime: trackedData.focusTime,
                    inputs: [{ 
                        key: key, 
                        timeDown: cTime,
                        timeUp: null, 
                        flightTime: 0,
                        interKeyTime: 0, 
                        timePressed: null,
                        timeDownFromStart: 0,
                        timeUpFromStart: 0,
                        occurrences: 1,
                        valueBefore: valueBefore
                    }]
                });
            } else {
                if(e.repeat) {
                    // if key keeps pressed down it is always the last key down
                    inputData[index].occurrences += 1;
                    setTrackedData({
                        startTime: trackedData.startTime,
                        focusTime: trackedData.focusTime,
                        inputs: inputData
                    }); 
                } else {
                    setTrackedData({
                        startTime: trackedData.startTime,
                        focusTime: trackedData.focusTime,
                        inputs: [
                            ...inputData,
                            {
                                key: key,
                                timeDown: cTime,
                                timeUp: null,
                                flightTime: null,
                                interKeyTime: null,
                                timePressed: null,
                                timeDownFromStart: null,
                                timeUpFromStart: null,
                                occurrences: 1,
                                valueBefore: valueBefore
                            }
                        ]
                    });
                }
            }
        }
    };

    const keyUp = (e) => {
        const key = e.key;
        if(key !== 'Enter' && key !== 'Tab') {
            const cTime = Date.now();
            // copy
            let inputData = JSON.parse(JSON.stringify(trackedData.inputs));

            // not necessary the last key if user keeps pressing key(s)
            for(let i = inputData.length - 1; i >= 0; i--) {
                const c = inputData[i];
                if(c.key === key && !c.timeUp) {
                    inputData[i] = {...c, ...{ timeUp: cTime, timePressed: cTime - c.timeDown }};
                    setTrackedData({
                        startTime: trackedData.startTime,
                        focusTime: trackedData.focusTime,
                        inputs: inputData
                    });
                    break;
                }
            }
        }
    };

    const paste = (e) => {
        e.preventDefault();
        stopAction('Action forbidden');
    };

    const compositionStart = (e) => {
        composedRef.current = true;
    };

    const compositionEnd = (e) => {
       composedRef.current = false;
    };

    const input = (e) => {
        const nEvent = e.nativeEvent;
        const d = nEvent.data;
        // if input length equals 1 i assume a normal input (pasting/autofill will be longer than 1). 
        // for chrome the lenght can be > 1 for composed keys (e.g. ^1).
        // in this case the type is 'insertCompositionText' instead of 'insertText'
        // => 'insertCompositionText' also accepted
        inputRef.current = d ? d.length === 1 || nEvent.inputType === 'insertCompositionText' : true;
    };

    const blur = () => {
        composedRef.current = false;
        inputRef.current = false;
        keyDownRef.current = false;
    };

    const focus = () => {
        if(trackedData.focusTime === null) {
            setTrackedData({
                startTime: trackedData.startTime,
                focusTime: Date.now(),
                inputs: trackedData.inputs
            });
        }
    };

    return (
        <Paper
            elevation={3}
            component="form"
            sx={{
                 position: 'relative',
                 display: 'flex',
                 flexDirection: 'column',
                 margin: '40px',
                 width: '400px',
                 padding: '20px',
                 height: '250px',
                 justifyContent: 'center'
                }}
            noValidate
            autoComplete='off'
            onSubmit={submit}
            >
            <InfoPopper 
                title='On the top of this box you can find your current password (changes each round). 
                Type it into the input field below and hit the Enter key or the submit button.'/>
            <Typography 
                variant='h7'
                component='div'
                sx={{marginBottom: '20px'}}>
                Your password: <BoldText>{password}</BoldText>
            </Typography>
            <Box>
                {capsLock ?
                    <Box sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'end',
                        flexDirection: 'row',
                        marginBottom: '3px',
                        marginLeft: '14px',
                        marginRight: '14px'
                        }}>
                        <KeyboardCapslockIcon
                            color='warning' 
                            fontSize='small'/>
                        <Typography
                            color='#ed6c02'
                            variant='caption'>
                            Caps Lock Active
                        </Typography>
                    </Box>
                    :
                    null
                }
                <TextField
                    fullWidth
                    inputRef={ref}
                    sx={{
                        marginBottom: '20px'
                    }}
                    label="Enter Password"
                    type="text"
                    required
                    error={passwordError ? true : false}
                    helperText={passwordError}
                    onChange={passwordChange}
                    onPaste={paste}
                    onKeyDown={keyDown}
                    onKeyUp={keyUp}
                    value={passwordInput}
                    autoComplete='off'
                    disabled={loading}
                    onCompositionStart={compositionStart}
                    onCompositionEnd={compositionEnd}
                    onInput={input}
                    onBlur={blur}
                    onFocus={focus}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment 
                                position="end"
                                disablePointerEvents>
                                {passwordSuccess && <CheckCircleIcon color='success'/>}
                                {passwordError && <ErrorIcon color='error'/>}
                            </InputAdornment>
                        ),
                        autoComplete: 'off',
                        sx: {
                            fontFamily: 'password',
                            backgroundColor: capsLock ? 'rgba(237, 108, 2, .1)' : '#ffffff'
                        },
                        className: 'password-input',
                        spellCheck: false,
                    }}/>
            </Box>
            <Box sx={{width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end'
                    }}>     
                <ActionButton 
                    clickCallback={submit}
                    loading={loading}
                    isLoadingButton={true}
                    icon={<SendIcon />}>
                    Submit Password
                </ActionButton>
            </Box>
        </Paper>
    );
})

export default PasswordForm;