import StudyPage from './components/studyPage';
import ConsentPage from './components/consentPage';
import DemographicsPage from './components/demographicsPage';
import ThankYouPage from './components/thankYouPage';
import MobilePage from './components/mobilePage';
import ErrorPage from './components/errorPage';

export const routes = [
    {
        path: "consent",
        element: <ConsentPage />,
    },
    {
        path: "questionnaire",
        element: <DemographicsPage />,
    },
    {
        path: "study",
        element: <StudyPage />,
    },
    {
        path: "thank-you",
        element: <ThankYouPage />,
    },
    {
        path: "device-warning",
        element: <MobilePage />,
    },
    {
        path: "error",
        element: <ErrorPage />,
    }
];