import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Training = ({ text }) => {

    return (
        <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
            }}>
            <Typography variant='h3'>
                {text}
            </Typography>
            <Typography variant='h6'>
                Submissions
            </Typography>
        </Box>
    )
}

export default Training;