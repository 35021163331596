import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const Transition = (props) => {
    return <Slide {...props} direction="up" />;
}

const Message = ({isOpen, close, msg, severity, duration}) => {
    
    return (
        <Snackbar 
            open={isOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} 
            TransitionComponent={Transition}
            autoHideDuration={duration || 6000} 
            onClose={close}>
            <Alert
                onClose={close}
                severity={severity || 'error'}
                variant='filled'
                sx={{ width: '100%', alignItems: 'center' }}>
                {msg}
            </Alert>
        </Snackbar>
    );
}

export default Message;