import InfoBox from './infoBox';
import StudyComp from './studyComp';
import { useOutletContext } from "react-router-dom";
import { motion } from "framer-motion";

const StudyPage = () => {

    const { studyState, setStudyState, setAllowedPath, capsLock } = useOutletContext();

    return (
        <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{ opacity: 0}}>
            <InfoBox studyState={studyState}/>
            <StudyComp 
                studyState={studyState}
                setAllowedPath={setAllowedPath}
                setStudyState={setStudyState}
                capsLock={capsLock}
                />
        </motion.div>
    )
}

export default StudyPage;