import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { ReactComponent as Logo } from '../lmu.svg';

const TopBar = ({ text }) => {

    return (
        <AppBar
            sx={{height: '86px'}} 
            position="sticky" 
            >
            <Toolbar>
                <Logo />
                <Typography 
                    variant="h6"
                    sx={{alignSelf: 'flex-end', padding: '0 15px', justifySelf: 'center'}}
                    >
                    {text}
                </Typography>  
            </Toolbar>
        </AppBar>   
    );

}

export default TopBar;