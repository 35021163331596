
import Box from '@mui/material/Box';
import Logo from '../lmu_footer.svg';
import  Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Link from '@mui/material/Link';

const Footer = ({ studyName }) => {

    return (
        <Box
            component={'footer'}
            sx={{
                width: '100%',
                minHeight: '225px',
                flexShrink: 0,
                background: `rgba(35, 35, 35, 0.95) url(${Logo}) -45% center no-repeat`,
                backgroundSize: '150% 150%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                padding: '20px 0'
            }}>
            <Container
                maxWidth={'xl'}
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'space-between'

                }}>
                <Box>
                    <Typography 
                        variant='h6' 
                        color={'white'}
                        >
                        {studyName}
                    </Typography>
                    <Typography 
                        variant='subtitle1' 
                        color={'#c0c1c3'}
                        sx={{
                            marginLeft: '10px'
                        }}
                        >
                        A study at <Link 
                            href={'https://www.lmu.de/'}  
                            underline="hover"
                            target="_blank"
                            >
                                LMU Munich
                        </Link> and <Link 
                            href={'https://unibw.de/'}  
                            underline="hover"
                            target="_blank"
                            >
                                UniBw Munich 
                        </Link>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        paddingTop: '20px',
                    }}
                    >
                    <Typography
                        sx={{
                            lineHeight: 2
                        }} 
                        color={'#c0c1c3'}
                        >
                        In case of questions, concerns or technical issues please contact:
                    </Typography>
                    <Box
                        sx={{
                            marginLeft: '10px'
                        }}
                        >
                        <Typography color={'#fff'}>
                            Timo Krapf
                        </Typography>
                        <Typography color={'#c0c1c3'}>
                            Student at LMU Munich
                        </Typography>
                        <Typography color={'#c0c1c3'}>
                            Email: <Link
                                        color={'#fff'}
                                        underline="hover"
                                        href="mailto:t.krapf@campus.lmu.de"
                                    >
                                        t.krapf@campus.lmu.de
                                    </Link>
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
}

export default Footer;