import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ThankYouNotAV = ({ reason }) => {

    return (
        <Box>
            <Typography>
                Thank you for considering our study!
            </Typography>
            <br/>
            <Typography>
               Unfortunately, {reason}. Therefore, no new participants can take part anymore.
            </Typography>
        </Box>
    );
}

export default ThankYouNotAV;