import Typography from '@mui/material/Typography';
import PrimaryPageContent from './primaryPageContent';
import Box from '@mui/material/Box';
import ActionButton from './actionButton';
import { downloadFile, storeLocally } from '../helpers';
import { useOutletContext } from "react-router-dom";
import config from '../config';
import BoldText from './boldText';
import BulletPointList from './bulletPointList';
import Divider from '@mui/material/Divider';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import Message from './message';
import { motion } from "framer-motion";
import SendIcon from '@mui/icons-material/Send';

const ConsentPage = () => {

    const { studyState, setAllowedPath, setStudyState } = useOutletContext();
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const buttonClicked = (e) => {
        const id = e.target.id;
        if(id === 'deny') {
            setAllowedPath('/thank-you?accepted=0'); 
        } else {
            setLoading(true);
            fetch((config.serverURL || '') + '/api/', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json'
                }
            }).then(response => {
                return response.json();
            }).then(json => {
                setLoading(false);
                const sentID = json.id;
                const state = json.state;
                if(sentID && state) {
                    storeLocally('id', sentID);
                    if(state.path) {
                        setAllowedPath(state.path + '?id=' + sentID);
                    }
                    setStudyState(state);
                } else {
                    setError(true);
                }
            }).catch(error => {
                setError(true);
                setLoading(false);
            });
        }
    };

    const download = () => {
        downloadFile('consent', 'informed_consent.pdf', () => setError(true), true);
    };

    const closeError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    return (
        <>
            <Message 
                msg='Something went wrong! Try reloading the page.' 
                isOpen={error} 
                close={closeError} />
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{ opacity: 0}}>
                <PrimaryPageContent>
                    <Box sx={{width: '100%'}}>
                        <Typography variant='h6' color='primary'>
                            Welcome to the study "{config.studyName}"!
                        </Typography>
                        <br/>
                        <Typography>
                            Thank you for your interest in our study! 
                            Before we can start, please read the Informed Consent of Participation carefully.
                            If you still want to participate, please click the accept button.
                            Below you find a shortened version with the most important information. 
                            In order to get the complete consent form, please click the download button. 
                        </Typography>
                        <br/>
                        <Typography component='div'>
                            <BoldText>Your task for this study:</BoldText> After filling out a pre-questionnaire about yourself
                            once, you will be asked to enter predefined passwords for a fixed amount of times. 
                            We call this process Session 1. {studyState.msToSession2 / 60 / 60 / 1000} hours after you are finished with that, Session 2 will be opened for you.
                            Here it will again be your task to enter predefined passwords. 
                            The complete study procedure will take you approximately 30 minutes.
                            Please use the same device and browser for both sessions. 
                            Please also make sure that the browser is NOT in Incognito mode and that the browser does NOT translate this website.
                            The language used on this website should be English.
                        </Typography>
                        <Divider sx={{marginTop: '40px'}}/>
                        <Typography 
                            variant='subtitle1'
                            sx={{paddingTop: '8px'}}>
                            <BoldText>Short version of consent form</BoldText>
                        </Typography>
                        <BulletPointList 
                            header='Purpose & Goal:'
                            items={[
                                    { text: "Creating a keystroke dataset used to improve password authentication by developing a typo-tolerant system" },
                                ]} />
                        <BulletPointList 
                            header='Study Procedure:'
                            ordered={true}
                            items={[
                                    { text: "Fill out pre-questionnaire (email, age, gender ...)" },
                                    { text: "Taking part in Session 1 (entering predefined passwords)" },
                                    { text: `Await the start of Session 2 (${studyState.msToSession2 / 60 / 60 / 1000} hours after completing Session 1)` },
                                    { text: "Taking part in Session 2 (entering predefined passwords)" },
                                ]} />
                        <BulletPointList 
                            header='Participation & Compensation:'
                            items={[
                                    { text: "Participation is voluntary and can be withdrawn at any time" },
                                    { text: "Participation will be compensated with 5€ Amazon Gift Card OR Participation Points (0.5)",
                                    subText: "For Participation Points you have to be a student of LMU Munich (Bachelor MMI)"
                                    },
                                    { text: "Extra 10€ Amazon Gift Card for the five participants who entered the passwords in Session 1/2 the fastest" },
                                    { text: "We reserve the right to exclude participants from the compensation if the main part of the study (Session 1/2) was not carried out properly" },
                                ]} />
                        <BulletPointList 
                            header='Recorded Data:'
                            items={[
                                    { text: "Personal data (email, age, gender ...)" },
                                    { text: "User input data" },
                                    { text: "Data will be anonymized and treated confidentially and secure" }
                                ]} />
                        <Divider />
                    </Box>
                    <Box sx={{ width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            padding: '20px 0',
                            flexWrap: 'wrap' }}>
                        <ActionButton
                            sx={{ marginRight: '20px',
                                marginTop: '20px' }}
                            id='download'
                            icon={<DownloadIcon />}
                            clickCallback={download}>
                            Download Full Consent
                        </ActionButton>
                        <Box sx={{ paddingTop: '20px' }}>
                            <ActionButton
                                id='deny'
                                sx={{marginRight: '20px'}} 
                                color='secondary'
                                clickCallback={buttonClicked}>
                                Deny (quit study)
                            </ActionButton>        
                            <ActionButton 
                                id='accept'
                                loading={loading}
                                isLoadingButton={true}
                                icon={<SendIcon />}
                                clickCallback={buttonClicked}>
                                Accept
                            </ActionButton>
                        </Box>
                    </Box>
                </PrimaryPageContent>
            </motion.div>
        </>
    );
}

export default ConsentPage;