import Box from '@mui/material/Box';
import styled from 'styled-components';

const Image = ({src, alt}) => {

    return (
        <Box sx={{width: 'auto', padding: '9px 0 0 20px'}}>
            <Img src={src} alt={alt}/>
        </Box>
        
    )
}

const Img = styled.img`
    width: 100%;
    max-width: 350px;
    height: auto;
    border: 2px solid black; 
    borderRadius: 4px;
`;

export default Image;