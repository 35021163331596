import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Timer = ({ time, running }) => {

    return (
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                marginTop: '30px'
            }}>
            <Typography 
                variant='h3'
                color={running ? 'rgba(0, 0, 0, 0.87)' : 'rgba(0, 0, 0, 0.26)'}>
                {time}
            </Typography>
            <Typography>
                {running ? '(stops after Time Trial)' : '(starts after Training)'}
            </Typography>
        </Box>
    );
}

export default Timer;