import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

const CustomTable = ({ header, body }) => {
    
    return (
        <TableContainer sx={{padding: '0 10px'}}>
            <Table aria-label="spanning table">
                <TableHead>
                    <TableRow>
                        <TableCell align="center" colSpan={4}>
                            {header}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align="center">
                            Session
                        </TableCell>
                        <TableCell align="center">
                            Amount of rounds/passwords
                        </TableCell>
                        <TableCell align="center">
                            Training repetitions
                        </TableCell>
                        <TableCell align="center">
                            Time Trial repetitions
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {body.map((el, i) => (
                        <TableRow key={header + i}>
                            <TableCell align="center">
                                {el.session}
                            </TableCell>
                            <TableCell align="center">
                                {el.rounds}
                            </TableCell>
                            <TableCell align="center">
                                {el.training}
                            </TableCell>
                            <TableCell align="center">
                                {el.testing}
                            </TableCell>
                        </TableRow>
                    ))}          
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default CustomTable;