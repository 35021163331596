import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BoldText from './boldText';
import ActionButton from './actionButton';
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from 'react';
import Message from './message';
import { downloadFile, getDisplayTime } from '../helpers';
import useInterval from './useInterval';
import Tooltip from '@mui/material/Tooltip';

const ThankYouSession1 = ({ 
    remainingTime,
    studyEnd, 
    reload, 
    msToSession2, 
    end1 }) => {

    const [error, setError] = useState(false);
    const [time, setTime] = useState(remainingTime);
    const [displayedTime, setDisplayedTime] = useState(getDisplayTime(remainingTime));

    const download = () => {
        downloadFile('calendar', 'session2.ics', () => setError(true));
    };

    const closeError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(false);
    };

    const reloadPage = () => {
        reload();
    };

    useInterval(() => {
        const t = msToSession2 - (Date.now() - end1);
        if(t <= 0) {
            setTime(0);
        }
        setDisplayedTime(getDisplayTime(t))
    }, 500, time);

    return (
        <>
            <Message msg='Something went wrong!' isError={error} closeError={closeError}/>
            <Box>
                <Typography>
                    Thank you for concluding Session 1!
                </Typography>
                <br/>
                {time > 0 ?
                    (<>
                        <Typography component={'span'}>
                            Session 2 opens in <BoldText>{displayedTime} hours</BoldText>.
                            We will send you a reminder via email containing the link for Session 2.
                            If you prefer to have an event in your calendar as well, click the download button.
                            The study will be closed on <BoldText>{studyEnd}</BoldText>.
                            Please make sure to finish Session 2 beforehand.
                            Since your only task is to submit the passwords half as many times, it will not take much time to complete.
                        </Typography>
                        <br/>
                        <br/>
                        <Typography component={'span'}>
                            You can close this tab now or keep it open and wait for the timer to run out.
                            If you prefer to close the page, we recommend that you save the page as a bookmark 
                            (not a must, as the link is also included in the reminder email) and open it again at the start of session 2.
                        </Typography>
                    </>)
                    :
                    (<>
                        <Typography component={'span'}>
                            <BoldText>Session 2 is open!</BoldText>
                        </Typography>
                        <br/>
                        <Typography component={'span'}>
                            Please click the "Start Session 2"-button.
                        </Typography>
                    </>)
                }
                <Box sx={{width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        padding: '20px 0'}}>
                    <Tooltip
                        placement='right-start'
                        title={time > 0 ? 'Session 2 is not yet open' : ''}>
                        <span>
                            <ActionButton
                                disabled={time > 0}
                                clickCallback={reloadPage}>
                                Start Session 2
                            </ActionButton>
                        </span>
                    </Tooltip>
                    <ActionButton
                        icon={<DownloadIcon />}
                        clickCallback={download}>
                        Download Event
                    </ActionButton>
                </Box>
            </Box>
        </>
        
    );
}

export default ThankYouSession1;