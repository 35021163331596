import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { motion } from "framer-motion";

const ErrorPage = () => {

    return (
        <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{ opacity: 0}}>
            <Box sx={{ flex: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '20px'}}>
                <Alert variant="outlined" severity="error">
                Server seems to be down. Please try again later!
                </Alert>
                <Typography>
                
                </Typography>
            </Box>
        </motion.div>
    );
}

export default ErrorPage;