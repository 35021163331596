import Paper from '@mui/material/Paper';

const PrimaryPageContent = ({ customSX, children }) => {

    customSX = customSX || {};
    return (
        <Paper
            elevation={5}
            sx={{
                ...{ position: 'relative',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  //borderRadius: '4px',
                  //boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.5)',
                  padding: '20px',
                  backgroundColor: '#fff',
                },
                ...customSX}}>
            {children}
        </Paper>
    );
}

export default PrimaryPageContent;