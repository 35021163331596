import TextField from '@mui/material/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Image from './image';

const MultipleChoice = ({id, 
                         controls, 
                         label, 
                         value, 
                         setValue,
                         textChange,
                         error,
                         infoText
                        }) => {
    
    return (
        <FormControl 
            required 
            sx={{width: '100%', margin: '30px 0'}}>
            <FormLabel
                sx={{
                    color: error || controls.filter(el => el.textError)[0] ? '#d32f2f' : 'rgba(0, 0, 0, 0.75)',
                    fontSize: '1.1rem'
                }}
                id={id}>
                    {label}
            </FormLabel>
            {(error || infoText) && <Typography 
                                        sx={{
                                            color: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
                                            fontWeight: '400',
                                            fontSize: '0.75rem',
                                            lineHeight: '1.66',
                                            letterSpacing: '0.03333em',
                                            textAlign: 'left',
                                            margin: '3px 14px 0 14px'
                                            }}>
                                        {error || infoText}
                                    </Typography>}
            <RadioGroup 
                aria-labelledby={id}
                id={'group-' + id}
                onChange={setValue}
                value={value}>
                {controls.map((el, i) => {
                    const val = el.label.toLowerCase();
                    
                    if(el.textLabel) {
                        return (<Box 
                                    sx={{display: 'flex'}}
                                    key={id + i + '-box'}>
                                    <FormControlLabel 
                                        value={val} 
                                        control={<Radio id={id + i + '-radio'}/>}
                                        id={id + i + '-control'} 
                                        label={el.label} />
                                    <TextField
                                        sx={{
                                            margin: '9px 0 9px 20px', 
                                            flex: 1,
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                backgroundColor: "#dddddd",
                                            }
                                        }}
                                        id={id + i + '-input'}
                                        value={el.textVal}
                                        required={el.textRequired ? true : false}
                                        disabled={el.textDisabled}
                                        onChange={textChange}
                                        error={el.textError ? true : false}
                                        helperText={el.textError}
                                        variant='outlined'
                                        label={el.textLabel}/>
                                </Box>);
                    } else if(el.img) {
                        return (<Box 
                                    sx={{display: 'flex'}}
                                    key={id + i + '-box'}>
                                    <FormControlLabel 
                                        value={val} 
                                        control={<Radio id={id + i + '-radio'}/>}
                                        id={id + i + '-control'} 
                                        label={el.label} />
                                    <Box sx={{ display: 'flex',
                                               alignItems: 'center',
                                               justifyContent: 'center',
                                               flexDirection: 'column' }}>
                                        <Image src={el.img.src} alt={el.img.alt}/>
                                        <Typography 
                                            variant='caption'
                                            sx={{paddingLeft: '20px', paddingBottom: '9px'}}>
                                            {el.img.source}
                                        </Typography>
                                    </Box>
                                </Box>);                        
                    }
                    return <FormControlLabel
                                key={id + i} 
                                value={val}
                                id={id + i + '-control'}  
                                control={<Radio id={id + i + '-radio'}/>} 
                                label={el.label}/>
                })}
            </RadioGroup>
        </FormControl>
    )
}

export default MultipleChoice;
