import Typography from '@mui/material/Typography';
import PrimaryPageContent from './primaryPageContent';
import { useLocation, useOutletContext } from "react-router-dom";
import ThankYouNotAccepted from './thankYouNotAccepted';
import ThankYouSession1 from './thankYouSession1';
import ThankYouSession2 from './thankYouSession2';
import ThankYouNotAV from './thankYouNotAV';
import Box from '@mui/material/Box';
import { motion } from "framer-motion";

const ThankYouPage = () => {

    const { studyState, setAllowedPath, reload } = useOutletContext();
    const location = useLocation();

    const linkClicked = (e) => {
        e.preventDefault();
        setAllowedPath('/consent');
    }

    const createContent = () => {
        const search = location.search;
        if(studyState.finished) {
            return <ThankYouNotAV 
                        reason={
                            studyState.finished === 1 ? 
                            'the limit of participants has already been reached' : 
                            'the study will close soon'
                        }/>;
        }
        if(search === '?accepted=0') {
            return <ThankYouNotAccepted 
                        link={'/consent'} 
                        linkClicked={linkClicked} />;
        }
        if(studyState.end1 && !studyState.end2) {
            const now = Date.now()
            const t = studyState.end1 || now;
            const options = {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
            }
            const studyEnd = new Date(studyState.studyEnd).toLocaleDateString("en-GB", options)
            return <ThankYouSession1
                        reload={reload} 
                        remainingTime={Math.max(studyState.msToSession2 - (now - t), 0)}
                        msToSession2={studyState.msToSession2}
                        end1={t}
                        studyEnd={studyEnd} />
        }
        if(studyState.end2) {
            return <ThankYouSession2 />
        }

        return null;
    }

    return (
        <motion.div
            initial={{opacity: 0}}
            animate={{opacity: 1}}
            exit={{ opacity: 0}}>
            <PrimaryPageContent>
                <Box sx={{width: '100%'}}>
                    <Typography 
                        variant='h6'
                        color='primary'
                        sx={{marginBottom: '5px'}}>
                        Thank you!
                    </Typography>
                    <br/>
                    {createContent()}
                </Box> 
            </PrimaryPageContent>
        </motion.div>
    );
}

export default ThankYouPage;