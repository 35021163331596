import config from "./config";

export function getDisplayTime(milliSecs) {
    if(milliSecs <= 0) {
        return '00:00:00';
    }

    const seconds = Math.ceil(milliSecs / 1000);
 
    /**
     * Retrieved from https://quickref.me/convert-seconds-to-hh-mm-ss-format.html
     */
    const t = [parseInt(seconds / 60 / 60),
               parseInt(seconds / 60 % 60), 
               parseInt(seconds % 60)].join(':').replace(/\b(\d)\b/g, '0$1');
    return t;
};

export function getTime(displayTime, inMillisecs=true) {
    const time = displayTime.split(':');
    const parsed = parseInt(time[0]) * 60 + parseInt(time[1]);
    if(inMillisecs) {
        return parsed * 1000
    }
    /**
     * Retrieved from https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
     */
    return `${Math.round(((parsed / 60) + Number.EPSILON) * 100) / 100} minutes`
};

export function getTimerValue(milliSecs) {
    /**
     * Retrieved from https://quickref.me/convert-seconds-to-hh-mm-ss-format.html
     * with minor changes
     */
    if(!milliSecs || milliSecs <= 0) {
        return '00:00,00';
    }

    let tArr = [];
    if(milliSecs >= 3600000) {
        tArr = [
            parseInt(milliSecs / 1000 / 60 / 60),
            parseInt(milliSecs / 1000 / 60 % 60), 
            parseInt(milliSecs / 1000 % 60)
        ]
    } else {
        tArr = [
            parseInt(milliSecs / 1000 / 60 % 60), 
            parseInt(milliSecs / 1000 % 60)
        ]
    }
    const t = (tArr.join(':') + ',' + parseInt(milliSecs / 10 % 100 )).replace(/\b(\d)\b/g, '0$1');

    return t;
};

export function storeLocally(key, value) {
    try {
        localStorage.setItem(key, value);
    } catch(error) {
        console.log(error);
    }
};

function getRoute() {
    const params = new URL(document.location).searchParams;
    const paramID = params.get('id');
    const storageID = localStorage.getItem('id');
    return storageID || paramID;
};

export function updateDB(data, successCallback=null, errorCallback=null) {
    const route = getRoute();
    if(route) {
        const endpoint = (config.serverURL || '') + '/api/' + route;
        fetch(endpoint, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(response => {
            return response.json();
        }).then(json => {
            if(successCallback) {
                successCallback(json);
            }
        }).catch(error => {
            console.log(error);
            if(errorCallback) {
                errorCallback(error);
            }
        });        
    } else {
        console.log('Request not possible');
        if(errorCallback) {
            errorCallback();
        }
    }
};

export function downloadFile(path, filename, errorCallback=null, ignoreID=false) {
    const route = getRoute();
    if(route || ignoreID) {
        const endpoint = (config.serverURL || '') + 
            `/api/${path}` +
            (route ? `/${route}` : '');

        fetch(endpoint, {
            method: 'GET',
        })
        .then(res => {
            if(res.status === 200) {
                return res.blob();
            } else {
                throw new Error();
            }
        })
        .then(blob => {
            /**
             * Retrieved from https://plainenglish.io/blog/how-to-download-files-with-javascript
             * with minor changes
            */
            const a = document.createElement("a");
            const href = URL.createObjectURL(blob);
            a.href = href;
            a.setAttribute("download", filename);
            a.setAttribute("target", "_blank");
            a.click();
            URL.revokeObjectURL(href);
        }).catch(() => {
            if(errorCallback) {
                errorCallback();
            }
        })    
    } else if(errorCallback) {
        errorCallback();
    }
}