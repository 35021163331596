import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';

const ActionButton = ({children, 
                       clickCallback, 
                       id, 
                       sx, 
                       color,
                       icon,
                       disabled,
                       isLoadingButton,
                       loading
                     }) => {

    return (
        <>
            {isLoadingButton ?
                <LoadingButton
                    id={id}
                    onClick={clickCallback}
                    endIcon={icon}
                    loading={loading}
                    loadingPosition="end"
                    variant="contained">
                    <span>{children}</span>
                </LoadingButton>
                 :
                <Button
                    id={id}
                    sx={sx}
                    onClick={clickCallback}
                    variant="contained"
                    endIcon={icon}
                    disabled={disabled ? true : false}
                    color={color || "primary"}>
                    {children}
                </Button>
            }
         </>
    )
}

export default ActionButton;