import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const ThankYouNotAccepted = ({ link, linkClicked }) => {

    return (
        <Box>
            <Typography>
                Thank you for considering our study!
            </Typography>
            <br/>
            <Typography>
                In case denying the study conditions was a mistake and 
                you still want to participate click <Link href={link} underline="hover" onClick={linkClicked}>here</Link>.
            </Typography>
        </Box>
    );
}

export default ThankYouNotAccepted;