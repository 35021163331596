import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const BulletPointList = ({header, items, ordered}) => {
    return (
        <>
            <Typography 
                variant='subtitle1' 
                sx={{
                    fontStyle: 'italic', 
                    paddingTop: '8px' }}>
                {header}
            </Typography>
            <List sx={{ listStyleType: ordered ? 'decimal' : 'disc', 
                        paddingLeft: 4, 
                        paddingTop: '0px' }}>
                {items.map((i, index) => 
                    (<ListItem 
                        key={header+index}
                        sx={{ 
                            display: 'list-item', 
                            padding: 0 
                            }}>
                        <ListItemText 
                            primary={i.text}
                            secondary={i.subText}/>
                    </ListItem>
                    ))
                }
            </List>
        </>
    );
}

export default BulletPointList;