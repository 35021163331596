import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import PrimaryPageContent from './primaryPageContent';
import { updateDB } from '../helpers';
import { useLocation, useOutletContext } from "react-router-dom";
import MultipleChoice from './multipleChoice';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/Send';
import qwerty from '../layouts/qwerty.png';
import qwertz from '../layouts/qwertz.png';
import Message from './message';
import { motion } from "framer-motion";
import Question from './question';
import ActionButton from './actionButton';

const baseError = {
    email: '',
    compensation: {val: '', matNumber: ''},
    gender: '',
    age: '',
    education: '',
    usage: {hours: false, minutes: false},
    layout: ''
};

const DemographicsPage = () => {

    const { setAllowedPath } = useOutletContext();
    const location = useLocation();

    const [questionnaire, setQuestionnaire] = useState({
        email: '',
        compensation: {val: '', matNumber: ''},
        gender: {val: '', other: ''},
        age: '',
        education: '',
        usage: {hours: '', minutes: ''},
        layout: {val: '', other: ''}
    });

    const [questionnaireError, setQuestionnaireError] = useState(baseError);

    const [error, setError] = useState({isError: false, msg: ''});

    const [loading, setLoading] = useState(false);

    const isCorrectInt = (val, limit) => {
        return /^(0|[1-9][0-9]*)$/.test(val.trim()) && parseInt(val) >= 0 && parseInt(val) <= limit;
    };

    const submit = () => {
        const error = {};
        const baseMessage = 'Please choose an answer';
        /**
         * Regex retrieved from https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#validation
         */
        const emailRegex = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/);
        for (const [key, value] of Object.entries(questionnaire)) {
            if(key === 'compensation') {
                if(value.val === 'participation points' && !value.matNumber) {
                    error[key] = {val: '', matNumber: 'Please enter your matriculation number'};
                } else if(!value.val) {
                    error[key] = {val: baseMessage, matNumber: ''};
                }
            } else if(key === 'gender' && !value.val) {
                error[key] = baseMessage;
            } else if(key === 'layout' && !value.val) {
                error[key] = baseMessage;
            } else if(key === 'email') {
                if(!value) {
                    error[key] = 'Please enter your email address';
                } else if(!emailRegex.test(value.trim())) {
                    error[key] = 'Not a valid email address';
                }
            } else if(key === 'age') {
                if(!value) {
                    error[key] = 'Please enter your age';
                } else if(!isCorrectInt(value, 130)) {
                    error[key] = 'Please enter a valid age (0 – 130)'
                }
            } else if(key === 'usage') {
                const hourError = !value.hours || !isCorrectInt(value.hours, 23);
                const minuteError = !value.minutes || !isCorrectInt(value.minutes, 59);
                if(hourError || minuteError) {
                    error[key] = {
                        hours: hourError,
                        minutes: minuteError
                    }
                }  
            } else if(!value) {
                error[key] = baseMessage;
            }
        }

        if(Object.keys(error).length === 0) {
            setLoading(true);
            updateDB({
                questionnaire: questionnaire,
            }, (json) => {
                if(json.code === '200') {
                    setAllowedPath('/study' + location.search);
                } else {
                    setError({isError: true, msg: json.msg});
                    if(json.code === '400') {
                        setQuestionnaireError({...baseError, ...{email: json.msg}});
                    }
                }
                setLoading(false);
            }, () => {
                setError({isError: true, msg: 'Some error happend! Please try again.'})
                setLoading(false);
            });
        } else {
            setQuestionnaireError({...baseError, ...error});
            setError({isError: true, msg: 'Questionnaire incomplete/invalid'});
        }
        
    };

    const setValue = (e) => {
        const id = e.target.id;
        const val = e.target.value;
        if(id === 'email') {
            setQuestionnaire(prev => ({
                ...prev,
                email: val
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                email: ''
            }));
        } else if(id.startsWith('compensation')) {
            setQuestionnaire(prev => ({
                ...prev,
                compensation: {val: val, matNumber: ''}
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                compensation: {val: '', matNumber: ''}
            }));
        } else if(id.startsWith('gender')) {
            setQuestionnaire(prev => ({
                ...prev,
                gender: {val: val, other: ''}
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                gender: ''
            }));
        } else if(id.startsWith('layout')) {
            setQuestionnaire(prev => ({
                ...prev,
                layout: {val: val, other: ''}
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                layout: ''
            }));
        } else if(id.startsWith('age')) {
            setQuestionnaire(prev => ({
                ...prev,
                age: val
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                age: ''
            }));
        } else if(id.startsWith('education')) {
            setQuestionnaire(prev => ({
                ...prev,
                education: val
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                education: ''
            }));
        } else if(id.startsWith('usage')) {
            setQuestionnaire(prev => ({
                ...prev,
                usage: { 
                    hours: id === 'usageHours' ? val : prev.usage.hours, 
                    minutes: id === 'usageMinutes' ? val : prev.usage.minutes
                }
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                usage: { 
                    hours: id === 'usageHours' ? false : prev.usage.hours, 
                    minutes: id === 'usageMinutes' ? false : prev.usage.minutes
                }
            }));
        }
    };

    const textChange = (e) => {
        const id = e.target.id;
        const val = e.target.value;
       
        if(id.startsWith('compensation')) {
            setQuestionnaire(prev => ({
                ...prev,
                compensation: {val: prev.compensation.val, matNumber: val}
            }));
            setQuestionnaireError(prev => ({
                ...prev,
                compensation: {val: '', matNumber: ''}
            }));
        } else if(id.startsWith('gender')) {
            setQuestionnaire(prev => ({
                ...prev,
                gender: {val: prev.gender.val, other: val}
            }));
        } else if(id.startsWith('layout')) {
            setQuestionnaire(prev => ({
                ...prev,
                layout: {val: prev.layout.val, other: val}
            }));
        }
    };

    const closeError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setError(prev => ({isError: false, msg: prev.msg }));
    };

    return (
        <>
            <Message 
                close={closeError} 
                msg={error.msg} 
                isOpen={error.isError} />
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                exit={{ opacity: 0}}>
                <PrimaryPageContent>
                    <Box sx={{width: '100%'}}>
                        <Typography variant='h6' color='primary'>
                            Pre-Questionnaire
                        </Typography> 
                    </Box>
                    <Box
                        component="form"
                        noValidate
                        sx={{width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center'}}>
                        <Box sx={{width: '65%'}}>
                            <Typography sx={{fontWeight: 'bold', margin: '20px 0'}}>
                                Please answer following questions. Mandatory fields are marked with "*".
                            </Typography>
                            <Question 
                                id='emailForm'
                                error={questionnaireError.email}
                                infoText={questionnaireError.email ? questionnaireError.email : 'Necessary to receive the compensation and the reminder for the 2. study session.'}
                                label='What is your email address?'
                                >
                                <TextField
                                    sx={{
                                        margin: '0 0 9px 0'
                                    }}
                                    fullWidth
                                    required
                                    type='email'
                                    id='email'
                                    value={questionnaire.email}
                                    onChange={setValue}
                                    label='Your email address'
                                    error={questionnaireError.email ? true : false}
                                    variant='outlined'
                                    />
                            </Question>
                            <MultipleChoice 
                                id='compensation'
                                label='What kind of compensation do you want to receive?'
                                value={questionnaire.compensation.val}
                                error={questionnaireError.compensation.val}
                                setValue={setValue}
                                textChange={textChange}
                                infoText='Participation points are only available to students of LMU Munich (Bachelor MMI).'
                                controls={[
                                    {label: 'Amazon gift card'},
                                    {label: 'Participation points', 
                                    textLabel: 'Matriculation Number',
                                    textVal: questionnaire.compensation.matNumber,
                                    textError: questionnaireError.compensation.matNumber,
                                    textDisabled: questionnaire.compensation.val !== 'participation points',
                                    textRequired: true},
                                    ]}/>
                            <MultipleChoice 
                                id='gender'
                                label=' How would you describe your gender identity?'
                                value={questionnaire.gender.val}
                                error={questionnaireError.gender}
                                setValue={setValue}
                                textChange={textChange}
                                controls={[
                                    {label: 'Female'},
                                    {label: 'Male'},
                                    {label: 'Other', 
                                     textLabel: 'Please specify (optional)',
                                     textVal: questionnaire.gender.other, 
                                     textDisabled: questionnaire.gender.val !== 'other'},
                                    {label: 'Prefer not to say'},
                                    ]}/>
                            <Question 
                                id='ageForm'
                                error={questionnaireError.age}
                                label='How old are you?'
                                >
                                <TextField
                                    sx={{
                                        margin: '0 0 9px 0'
                                    }}
                                    fullWidth
                                    required
                                    type='text'
                                    id='age'
                                    value={questionnaire.age}
                                    onChange={setValue}
                                    label='Your age in years'
                                    error={questionnaireError.age ? true : false}
                                    variant='outlined'
                                    />
                            </Question>
                            <MultipleChoice 
                                id='education'
                                value={questionnaire.education}
                                error={questionnaireError.education}
                                setValue={setValue}
                                textChange={textChange}
                                label='What is the highest degree or level of school you have completed?'
                                infoText='The selection options are ordered from top to bottom and start with the lowest degree/level.'
                                controls={[
                                    {label: 'No schooling completed'},
                                    {label: 'General Certificate of Secondary Education (e.g. Mittlere Reife, Hauptschulabschluss ...)'},
                                    {label: 'General Certificate of Education (e.g. high school diploma, Abitur, Fachabitur ...)'},
                                    {label: 'Vocational education (Ausbildung)'},
                                    {label: "Bachelor's degree or comparable (e.g. Meister, Techniker ...)"},
                                    {label: "Master's degree or comparable"},
                                    {label: 'Doctoral degree'},
                                    {label: 'Habilitation'},
                                    ]}/>
                            <Question 
                                id='usageForm'
                                error={questionnaireError.usage.hours || questionnaireError.usage.minutes ? 'Please specify your average usage (hours between 0 and 23, minutes between 0 and 59)' : ''}
                                label='How much time do you spend on a Laptop/Notebook/Desktop per day on average?'
                                infoText='An estimation is enough (e.g. 8 hours a day from Monday to Friday is approx. 5 hours and 45 minutes).'
                                >
                                <Box 
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        alignItems: 'center',
                                        justifyContent: 'space-between'
                                    }}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '0 0 9px 0',
                                            flexGrow: 1,
                                            minWidth: '225px',
                                            marginRight: '17.4px'
                                        }}>
                                        <TextField
                                            sx={{
                                                flexGrow: 1
                                            }}
                                            required
                                            type='text'
                                            id='usageHours'
                                            value={questionnaire.usage.hours}
                                            onChange={setValue}
                                            label='Hours of usage'
                                            error={questionnaireError.usage.hours}
                                            variant='outlined'
                                            />
                                        <Typography
                                            sx={{
                                                paddingLeft: '5px',
                                                fontWeight: '500',
                                                color: questionnaireError.usage.hours ? '#d32f2f' : 'rgba(0, 0, 0, 0.75)'
                                            }}>
                                            hours
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            padding: '0 0 9px 0',
                                            flexGrow: 1,
                                            minWidth: '225px',
                                        }}>
                                        <TextField
                                            sx={{
                                                flexGrow: 1
                                            }}
                                            required
                                            type='text'
                                            id='usageMinutes'
                                            value={questionnaire.usage.minutes}
                                            onChange={setValue}
                                            label='Minutes of usage'
                                            error={questionnaireError.usage.minutes}
                                            variant='outlined'
                                            />
                                        <Typography
                                            sx={{
                                                paddingLeft: '5px',
                                                fontWeight: '500',
                                                color: questionnaireError.usage.minutes ? '#d32f2f' : 'rgba(0, 0, 0, 0.75)'
                                            }}>
                                            minutes
                                        </Typography>
                                    </Box>
                                </Box>
                            </Question>
                            <MultipleChoice 
                                id='layout'
                                value={questionnaire.layout.val}
                                error={questionnaireError.layout}
                                setValue={setValue}
                                textChange={textChange}
                                label='Which keyboard layout does the device you are using for the study have?'
                                infoText='Note that the images are just examples. Layout names are often described by the order of the first six keys on the top letter row of the keyboard (e.g. in Germany usually "QWERTZ").'
                                controls={[
                                    {label: 'QWERTY', img: {src: qwerty, alt: 'QWERTY', source: 'Keyboard layout QWERTY (Apple, 2024)'}},
                                    {label: 'QWERTZ', img: {src: qwertz, alt: 'QWERTZ', source: 'Keyboard layout QWERTZ (Apple, 2024)'}},
                                    {label: 'Other', 
                                    textLabel: 'Please specify layout name/your country (optional)',
                                    textVal: questionnaire.layout.other, 
                                    textDisabled: questionnaire.layout.val !== 'other'},
                                    ]}/>
                        </Box>
                        <Box sx={{width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            padding: '20px 0'}}> 
                            <ActionButton 
                                clickCallback={submit}
                                loading={loading}
                                isLoadingButton={true}
                                icon={<SendIcon />}>
                                Submit answers
                            </ActionButton>    
                        </Box>
                    </Box>
                </PrimaryPageContent>
            </motion.div>
        </>
    );
}

export default DemographicsPage;