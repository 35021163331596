import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const Question = ({
        id,
        error,
        label,
        infoText,
        children 
    }) => {

    return (
        <FormControl 
            required 
            sx={{width: '100%', margin: '30px 0'}}>
            <FormLabel
                sx={{
                    color: error  ? '#d32f2f' : 'rgba(0, 0, 0, 0.75)',
                    fontSize: '1.1rem',
                    marginBottom: error || infoText ? 0 : '9px'
                }}
                id={id}>
                {label}
            </FormLabel>
            {(error || infoText) && <Typography 
                                        sx={{
                                            color: error ? '#d32f2f' : 'rgba(0, 0, 0, 0.6)',
                                            fontWeight: '400',
                                            fontSize: '0.75rem',
                                            lineHeight: '1.66',
                                            letterSpacing: '0.03333em',
                                            textAlign: 'left',
                                            margin: '3px 14px 9px 14px'
                                            }}>
                                        {error || infoText}
                                    </Typography>}
            {children}
        </FormControl>
    );
}

export default Question;