import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ThankYouSession2 = () => {

    return (
        <Box>
            <Typography>
                Thank you for completing the study!
            </Typography>
            <br/>
            <Typography>
               We will reach out to you regarding your compensation after the study is closed.
            </Typography>
        </Box>
    );
}

export default ThankYouSession2;