import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Loading = () => {
  return (
    <Box sx={{ flex: 1,
              display: 'flex',
              alignItems: 'center', 
              justifyContent: 'center',
              //width: '100vw',
              //height: '100vh'
              //height: 'calc(100vh - 86px)'
              }}>
      <CircularProgress />
    </Box>
  );
}

export default Loading;