import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import BoldText from './boldText';
import config from '../config';
import CustomTable from './table';

const InfoBox = ({studyState}) => {

    const getSessionText = (index) => {
        if(index === 0) {
            return `${index + 1} ${studyState.session === 1 ? '(now)' : '(done)'}`;
        } else {
            return `${index + 1} ${studyState.session === 2 ? '(now)' : '(' + studyState.msToSession2 / 60 / 60 / 1000 + ' hours later)'}`
        }
    }

    return (
        <Accordion 
            defaultExpanded
            elevation={5}
            >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{padding: '0 20px'}}
                >
                <Typography color='primary' variant='h6'>Session {studyState.session} Instructions</Typography>
            </AccordionSummary>
            <AccordionDetails sx={{padding: '10px 20px 20px 20px'}}>
                <Typography component='div' sx={{whiteSpace: 'pre-wrap'}}>
                    Welcome to the main part of the study <BoldText>"{config.studyName}"</BoldText>! 
                </Typography>
                <br/>
                <Typography component='div' sx={{whiteSpace: 'pre-wrap'}}>
                    In this study, your task will be to type predefined passwords as fast as you can after practicing them. 
                    The 5 fastest participants win an additional reward: a <BoldText>10€ Amazon Gift Card</BoldText>.
                    Before you start, please read the detailed instructions carefully. 
                    Afterwards you can close them by clicking on the arrow in the header of this box.
                    To reopen the instructions, simply click the arrow again.
                </Typography>
                <br/>
                <Typography component='div' sx={{whiteSpace: 'pre-wrap'}}>
                    The study consists of two sessions. You are currently in <BoldText>Session {studyState.session}</BoldText>.
                    Each session in itself consists of <BoldText>{studyState.passwords.length} rounds</BoldText> with one specific password assigned to you respectively.
                    Your task is to type your <BoldText>current password</BoldText> into the password input field 
                    and submit it (by hitting the Enter key or submit button).
                    Once you submit a password, the input field will be cleared and temporarily disabled while the entry is being checked.
                    Aftwards, you can type again as normal.
                </Typography>
                <br/>
                <Typography component='div' sx={{whiteSpace: 'pre-wrap'}}>
                    Each round begins with the "Training" phase followed by the "Time Trial" phase. 
                    The first phase is designed to practice your password <BoldText>without any time pressure</BoldText> by submitting it {studyState.repetitions[studyState.session - 1][0]} times.
                    Once you are done with that, the <BoldText>timer starts immediately</BoldText> and with it the second phase of the round.
                    Now we ask you to submit the password you practiced {studyState.repetitions[studyState.session - 1][1]} times, 
                    but in this case <BoldText>as fast as you can</BoldText>. After that the timer stops and the round ends.
                    Now you have the opportunity to take a short break before starting the next round.
                </Typography>
                <br/>
                <Typography component='div' sx={{whiteSpace: 'pre-wrap'}}>
                    <BoldText>Important:</BoldText> During the "Training" phase, the submitted passwords must be completely correct. 
                    In the "Time Trial" phase, we allow slightly wrong passwords. 
                    In both phases, you should not use the Backspace key to correct any input. 
                    If you press Backspace, it will clear the input field and show an error.
                    Therefore, instead of using Backspace, it is always better to submit the password as it is.
                </Typography>
                <br/>
                <Typography component='div' sx={{whiteSpace: 'pre-wrap'}}>
                    We want to remind you that it is in your interest to submit passwords in the "Time Trial" phase as fast as possible
                    since the 5 fastest participants of both sessions combined will be awarded the <BoldText>additional compensation</BoldText>.
                    Also be aware that while you can take short breaks between rounds, each session in itself must be completed in one go.
                    The state of the study (current round/phase, current password, timer, number of submissions) and the password submission form that needs to be used can be found <BoldText>below this instructions box</BoldText>.
                    To get an overview of the structure of Session 1 and 2, please have a look at the following table.
                </Typography>
                <CustomTable 
                    header='Study structure overview'
                    body={[...Array(2).keys()].map(i => ({
                        session: getSessionText(i),
                        rounds: studyState.passwords.length,
                        training: studyState.repetitions[i][0],
                        testing: studyState.repetitions[i][1],
                    }))}
                />
            </AccordionDetails>
            
        </Accordion>
    );
}

export default InfoBox;